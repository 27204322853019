import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import NavBar from "./components/fixtures/NavBar";
import Home from "./components/pages/Home"
import About from "./components/pages/About";
import MyNews from "./components/pages/MyNews";
import IssueTrackers from "./components/pages/IssueTrackers";
import Login from "./components/pages/Login";
import UserGuide from "./components/pages/UserGuide";
import USANews from "./components/pages/USANews";
import ChinaNews from "./components/pages/ChinaNews";
import TurkeyNews from "./components/pages/TurkeyNews";
import RussiaNews from "./components/pages/RussiaNews";
import IranNews from "./components/pages/IranNews";
import IPTracker from "./components/pages/IPTracker";
import Fringe from "./components/pages/Fringe";
import LiveNewsFeed from "./components/pages/LiveNewsFeed";
import OutcomeOneTracker from "./components/pages/OutcomeOneTracker";
import FinancialTracker from "./components/pages/FinancialTracker";
import ElectionFacts from "./components/pages/ElectionFacts";
import ElectionFactsVA from "./components/pages/ElectionFactsVA";
import ElectionFactsDD from "./components/pages/ElectionFactsDD";
import ElectionFactsUT from "./components/pages/ElectionFactsUT";
import ElectionFactsPA from "./components/pages/ElectionFactsPA";
import ElectionFactsIL from "./components/pages/ElectionFactsIL";
import ElectionFactsUSA from "./components/pages/ElectionFactsUSA";
import AskVerba from "./components/pages/AskVerba";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/fixtures/HeaderFooter.css";
import { UserProvider } from "./contexts/UserContext";
import { FirestoreProvider } from "./contexts/FirestoreContext";
import { TableDataProvider } from "./contexts/TableDataContext";
import Footer from './components/fixtures/Footer'; // adjust the path based on where you put the file
import "./index.css"
import AskVerbaDemo from "./components/pages/AskVerbaDemo";
import ShareArticle from "./components/pages/ShareArticle";
import NarrativeAnalysis from "./components/pages/NarrativeAnalysis";
import IssuePage from "./components/pages/IssuePage";
import RegionalNews from "./components/pages/RegionalNews";
import GlobalPulse from "./components/pages/GlobalPulse";
import CongressTracker from "./components/pages/CongressTracker";
import SenatorTracker from "./components/pages/SenatorTracker";
import RepresentativeTracker from "./components/pages/RepresentativeTracker";
import IrongateTracker from "./components/pages/IrongateTracker";
import SquadraTracker from "./components/pages/SquadraTracker";
import BofATracker from "./components/pages/BofATracker";
import CongressCandidateTracker from "./components/pages/CongressCandidateTracker";
import ElectionNarratives from "./components/pages/ElectionNarratives";
import ElectionNarrativesVisualizer from "./components/pages/ElectionNarrativesVisualizer";
import Election from "./components/pages/Election";

function CleanRouter({ children }) {
  let location = useLocation();
  let { pathname, search, hash } = location;

  // Search for the fbclid param in the URL search string
  const searchParams = new URLSearchParams(search);
  if (searchParams.has('fbclid')) {
    searchParams.delete('fbclid'); // Remove the fbclid parameter
    // Reconstruct the URL without fbclid
    const newSearch = searchParams.toString();
    return <Navigate to={`${pathname}${newSearch ? `?${newSearch}` : ''}${hash}`} replace />;
  }

  return children;
}

function App() {
  return (
    <UserProvider>
      <FirestoreProvider>
        <TableDataProvider>
          <Router>
            <>
              <div id="page-container"> {/* Page container */}
                <NavBar />
                <div
                  style={{
                    margin: "1rem auto",
                    maxWidth: 1500,
                    width: "98%",
                    padding: "0 .25rem",
                    overflowX: "hidden",
                  }}
                >
                  <div id="content-wrap"> {/* Content wrap */}
                    <CleanRouter>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <>
                              <Home />
                            </>
                          }
                        />
                        <Route path="/international" element={<IPTracker />} />
                        <Route path="/usa" element={<USANews />} />
                        <Route path="/turkey" element={<TurkeyNews />} />
                        <Route path="/china" element={<ChinaNews />} />
                        <Route path="/russia" element={<RussiaNews />} />
                        <Route path="/iran" element={<IranNews />} />
                        <Route path="/fringe" element={<Fringe />} />
                        <Route path="/congress" element={<CongressTracker />} />
                        <Route path="/livenewsfeed" element={<LiveNewsFeed />} />
                        <Route path="/financial" element={<FinancialTracker />} />
                        <Route path="/outcome/one" element={<OutcomeOneTracker />} />
                        <Route path="/irongate" element={<IrongateTracker />} />
                        <Route path="/squadra" element={<SquadraTracker />} />
                        <Route path="/jd_demo" element={<BofATracker />} />
                        <Route path="/election" element={<Election />} />
                        <Route path="/election_narratives" element={<ElectionNarratives />} />
                        {/* <Route path="/election_visualizer" element={<ElectionNarrativesVisualizer />} /> */}
                        <Route path="/election_facts" element={<ElectionFacts />} />
                        <Route path="/election_facts/virginia" element={<ElectionFactsVA />} />
                        <Route path="/election_facts/va" element={<ElectionFactsVA />} />
                        <Route path="/election_facts/dd" element={<ElectionFactsDD />} />
                        <Route path="/election_facts_va" element={<ElectionFactsVA />} />
                        <Route path="/election_facts_ut" element={<ElectionFactsUT />} />
                        <Route path="/election_facts_utah" element={<ElectionFactsUT />} />
                        <Route path="/election_facts/utah" element={<ElectionFactsUT />} />
                        <Route path="/election_facts/ut" element={<ElectionFactsUT />} />
                        <Route path="/election_facts_il" element={<ElectionFactsIL />} />
                        <Route path="/election_facts_illinois" element={<ElectionFactsIL />} />
                        <Route path="/election_facts/illinois" element={<ElectionFactsIL />} />
                        <Route path="/election_facts/il" element={<ElectionFactsIL />} />
                        <Route path="/election_facts/usa" element={<ElectionFactsUSA />} />
                        <Route path="/election_facts_usa" element={<ElectionFactsUSA />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/issue-trackers" element={<IssueTrackers />} />
                        <Route path="/mynews" element={<MyNews />} />
                        <Route path="/userguide" element={<UserGuide />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/ask" element={<AskVerba />} />
                        <Route path="/askdemo" element={<AskVerbaDemo />} />
                        <Route path="/globalpulse" element={<GlobalPulse />} />
                        <Route path="/article/*" element={<ShareArticle />} />
                        <Route path="/narrative/*" element={<NarrativeAnalysis />} />
                        <Route path="/us/*" element={<NarrativeAnalysis usaNarrative={true} />} />
                        <Route path="/tr/*" element={<NarrativeAnalysis turkeyNarrative={true} />} />
                        <Route path="/cn/*" element={<NarrativeAnalysis chinaNarrative={true} />} />
                        <Route path="/ru/*" element={<NarrativeAnalysis russiaNarrative={true} />} />
                        <Route path="/ir/*" element={<NarrativeAnalysis iranNarrative={true} />} />
                        <Route path="/fringenarrative/*" element={<NarrativeAnalysis fringeNarrative={true} />} />
                        <Route path="/issue-tracker/:issue" element={<IssuePage />} />
                        <Route path="/representative/:issue" element={<RepresentativeTracker />} />
                        <Route path="/senator/:issue" element={<SenatorTracker />} />
                        <Route path="/region/:issue" element={<RegionalNews />} />
                        <Route path="*" element={<div>URL not found</div>} />
                        <Route path="/race/:state/:district" element={<CongressCandidateTracker />} /> {/* New dynamic route */}
                      </Routes>
                    </CleanRouter>
                  </div>
                </div>
              </div>
            </>
            <Footer />
          </Router>
        </TableDataProvider>
      </FirestoreProvider>
    </UserProvider>
  );
}

export default App;
