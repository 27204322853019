import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle, FaSort, FaRegClone } from 'react-icons/fa';
import './Search.css';
import '../GlobalStyles.css';
import { RiShareBoxFill } from "react-icons/ri";
import { FaBars } from "react-icons/fa6";
import { FiShare } from "react-icons/fi";
import CustomBadge from "../fixtures/CustomBadge";
import { HiMiniBarsArrowDown, HiMiniBarsArrowUp } from "react-icons/hi2";


export function timeAgo(timestamp) {
    const now = new Date();
    const time = new Date(timestamp);
    const difference = now - time;

    const minutes = Math.floor(difference / (1000 * 60));
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(difference / (1000 * 60 * 60 * 24 * 7));
    const months = Math.floor(difference / (1000 * 60 * 60 * 24 * 30));
    const years = Math.floor(difference / (1000 * 60 * 60 * 24 * 365));

    if (years > 0) return `${years} ${years === 1 ? 'Year' : 'Years'} Ago`;
    if (months > 0) return `${months} ${months === 1 ? 'Month' : 'Months'} Ago`;
    if (weeks > 0) return `${weeks} ${weeks === 1 ? 'Week' : 'Weeks'} Ago`;
    if (days > 0) return `${days} ${days === 1 ? 'Day' : 'Days'} Ago`;
    if (hours > 0) return `${hours} ${hours === 1 ? 'Hour' : 'Hours'} Ago`;
    if (minutes > 0) return `${minutes} ${minutes === 1 ? 'Min' : 'Min'} Ago`;
    return 'Just Now';
};


export function getDatePublished(utcDateString) {
    const utcDate = new Date(utcDateString);
    return `${utcDate.getMonth() + 1}`.padStart(2, '0') +
        `/${utcDate.getDate()}`.padStart(2, '0') +
        `/${utcDate.getFullYear()}`;
}

export function getTimePublished(utcDateString) {
    const utcDate = new Date(utcDateString);
    let hours = utcDate.getHours();
    let minutes = utcDate.getMinutes();

    // Round minutes to the nearest 10, and adjust hours if necessary
    minutes = Math.ceil(minutes / 10) * 10;
    if (minutes === 60) {
        minutes = 0;
        hours++;
    }

    // If hours reach 24, set it back to 23 and minutes to 50
    if (hours === 24) {
        hours = 23;
        minutes = 50;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function getTimezoneName(utcDateString) {
    const date = new Date(utcDateString);
    const timezoneName = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date).split(', ')[1];
    return timezoneName;
}


function DataTableCluster({ data, showIndex = true, fringeCluster = false, chinaCluster = false, turkeyCluster = false, iranCluster = false, russiaCluster = false, usaCluster = false }) {

    const [expandedRows, setExpandedRows] = useState([]);

    const [filteredData, setFilteredData] = useState(data);

    const toggleRow = (index) => {
        const isExpanded = expandedRows.includes(index);
        if (isExpanded) {
            setExpandedRows(expandedRows.filter(i => i !== index));
        } else {
            setExpandedRows([...expandedRows, index]);
        }
    };

    const [expandAll, setExpandAll] = useState(false);

    const toggleExpandAll = () => {
        if (expandAll) {
            setExpandedRows([]);
        } else {
            setExpandedRows(filteredData.map((_, index) => index));
        }
        setExpandAll(!expandAll);
    };

    useEffect(() => {
        setFilteredData(data);
        if (expandAll) {
            setExpandedRows(data.map((_, index) => index));
        }
    }, [data, expandAll]);

    // Check if isMobile
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (clusterId, index) => {
        copyToClipboard(clusterId);
        setCopiedIndex(index);
        setTimeout(() => setCopiedIndex(null), 1000); // Reset the copied state after 2 seconds
    };

    const copyToClipboard = (link) => {
        const baseURL = window.location.origin; // Get the current site's base URL
        const path = fringeCluster ? 'fringenarrative' :
            chinaCluster ? 'cn' :
                turkeyCluster ? 'tr' :
                    iranCluster ? 'ir' :
                        russiaCluster ? 'ru' :
                            usaCluster ? 'us' :
                                'narrative';
        const formattedLink = `${baseURL}/${path}/${link}`;

        // Check if clipboard API is available
        if (!navigator.clipboard) {
            console.error('Clipboard not available.');
            return;
        }

        navigator.clipboard.writeText(formattedLink)
            .then(() => {
                console.log('Link copied to clipboard.'); // Or handle this silently
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    const handleShare = async (row) => {
        const path = fringeCluster ? 'fringenarrative' :
            chinaCluster ? 'cn' :
                turkeyCluster ? 'tr' :
                    iranCluster ? 'ir' :
                        russiaCluster ? 'ru' :
                            usaCluster ? 'us' :
                                'narrative';
        const url = `https://verbaai.org/${path}/${row.cluster_id}`;

        if (navigator.share) {
            try {
                await navigator.share({
                    title: row.current_title.replace(/\*/g, ''),
                    text: `"${row.current_title}"`, // Text to accompany the shared content
                    url: url, // The URL to share
                });
                console.log('Article shared successfully');
            } catch (error) {
                console.error('Error sharing the article:', error);
            }
        } else {
            console.error('Web Share API is not supported in your browser.');
        }
    };

    const renderMobileTableBeta = () => (
        <div>
            <Table>
                <tbody>
                    {filteredData.map((row, index) => {
                        const isExpanded = expandedRows.includes(index);
                        const path = fringeCluster ? 'fringenarrative' :
                            chinaCluster ? 'cn' :
                                turkeyCluster ? 'tr' :
                                    iranCluster ? 'ir' :
                                        russiaCluster ? 'ru' :
                                            usaCluster ? 'us' :
                                                'narrative';
                        return (
                            <React.Fragment key={index}>
                                <tr>
                                    <td style={{ width: '100%' }}>
                                        <div style={{ height: '0.75rem' }}></div>
                                        <div>
                                            {row.countries && (
                                                row.countries.slice(0, 2).map((country, index) => (
                                                    <span className="mr-05">
                                                        <a href={`/region/${country.toLowerCase().replace(/ /g, '_')}`}>
                                                            <CustomBadge
                                                                key={index}
                                                                text={country}
                                                                backgroundColor="#ebebeb"
                                                                textColor="black"
                                                            />
                                                        </a>
                                                    </span>
                                                ))
                                            )}
                                        </div>
                                        <div style={{ height: '0.5rem' }}></div>
                                        <a
                                            href={`/${path}/${row.cluster_id}`}
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            <h4>
                                                {row.current_title
                                                    .replace(/\*/g, '')  // Remove any asterisks
                                                    .replace(/([a-z])([A-Z])/g, '$1 $2')}  {/* Insert a space between lowercase and uppercase transitions */}
                                            </h4>
                                        </a>
                                        {timeAgo(row.update_timestamp_utc)} • {row.cluster_size} Articles<br />
                                        <div style={{ height: '0.75rem' }}></div>
                                    </td>
                                    <td style={{ verticalAlign: 'bottom', textAlign: 'center' }}>
                                        <div style={{ height: '0.75rem' }}></div>
                                        <div style={{ position: 'relative', width: '5.5rem', height: '5.5rem', overflow: 'hidden', borderRadius: '0.5rem', marginBottom: '0.5rem' }}>
                                            <img
                                                src={row.image_link && row.image_link !== 'No image found' ? row.image_link : 'https://i.imgur.com/iioqaDS_d.webp?maxwidth=760&fidelity=grand'}
                                                alt="Image description"
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop in case fallback image fails
                                                    e.target.src = 'https://i.imgur.com/iioqaDS_d.webp?maxwidth=760&fidelity=grand';
                                                }}
                                            />
                                        </div>
                                        <button
                                            onClick={() => toggleRow(index)}
                                            style={{ background: 'none', border: 'none', textDecoration: 'none', color: '#222222' }}
                                        >
                                            <FaBars style={{ fontSize: '1.75rem', color: '#222222' }} />
                                            <div style={{ height: '0.75rem' }}></div>
                                        </button>
                                    </td>
                                </tr>

                                {isExpanded && (
                                    <React.Fragment>
                                        <tr>
                                            <td colSpan={2}>
                                                <div>
                                                    <span style={{ whiteSpace: "pre-line" }}>
                                                        <div style={{ height: '0.75rem' }}></div>
                                                        {row.current_summary.replace(/^-\. /gm, "\u2022 ").replace(/^- /gm, "\u2022 ").replace(/\*/g, '').replace(/^\s*$(?:\r\n?|\n)/gm, '')}
                                                    </span>
                                                </div>
                                                <div style={{ height: '0.75rem' }}></div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                    <div></div>
                                                    <FiShare
                                                        style={{ fontSize: '1.5rem', color: '#222222', cursor: 'pointer' }}
                                                        onClick={() => handleShare(row)}
                                                    />
                                                </div>
                                                <div style={{ height: '0.75rem' }}></div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );


    const renderDesktopTableBeta = () => (
        <div>
            <Table>
                <tbody>
                    <tr>
                        <td colSpan="2" style={{ textAlign: 'right' }}>
                            <button onClick={toggleExpandAll} style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer', fontSize: '1.5rem', color: '#222222' }}>
                                {expandAll ? <HiMiniBarsArrowUp fontSize="1.5rem" /> : <HiMiniBarsArrowDown fontSize="1.5rem" />}
                            </button>
                        </td>
                    </tr>
                    {filteredData.map((row, index) => {
                        const isExpanded = expandedRows.includes(index);
                        const path = fringeCluster ? 'fringenarrative' :
                            chinaCluster ? 'cn' :
                                turkeyCluster ? 'tr' :
                                    iranCluster ? 'ir' :
                                        russiaCluster ? 'ru' :
                                            usaCluster ? 'us' :
                                                'narrative';
                        return (
                            <React.Fragment key={index}>
                                <tr>
                                    <td style={{ width: '100%' }}>
                                        <div style={{ height: '0.5rem' }}></div>
                                        <div>
                                            {row.countries && (
                                                row.countries.slice(0, 10).map((country, index) => (
                                                    <span className="mr-05">
                                                        <a href={`/region/${country.toLowerCase().replace(/ /g, '_')}`}>
                                                            <CustomBadge
                                                                key={index}
                                                                text={country}
                                                                backgroundColor="#ebebeb"
                                                                textColor="black"
                                                            />
                                                        </a>
                                                    </span>
                                                ))
                                            )}
                                        </div>
                                        <a
                                            href={`/${path}/${row.cluster_id}`}
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            <div>
                                                <div style={{ height: '0.5rem' }}></div>
                                                <h4>
                                                    {row.current_title
                                                        .replace(/\*/g, '')  // Remove any asterisks
                                                        .replace(/([a-z])([A-Z])/g, '$1 $2')}  {/* Insert a space between lowercase and uppercase transitions */}
                                                </h4>
                                                {timeAgo(row.update_timestamp_utc)} • {row.cluster_size} Articles<br />
                                            </div>
                                        </a>
                                        <div style={{ height: '0.75rem' }}></div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <button
                                                onClick={() => toggleRow(index)}
                                                style={{ background: 'none', border: 'none', textDecoration: 'none', color: '#222222' }}>
                                                <FaBars style={{ fontSize: '1.5rem', color: '#222222', marginRight: '2rem' }} />
                                            </button>
                                            <FaRegClone
                                                style={{
                                                    fontSize: copiedIndex === index ? '1.6rem' : '1.5rem',
                                                    cursor: 'pointer',
                                                    marginRight: '2rem'
                                                }}
                                                onClick={() => handleCopy(row.cluster_id, index)}
                                            />
                                        </div>
                                        <div style={{ height: '0.75rem' }}></div>
                                    </td>
                                    <td style={{ verticalAlign: 'bottom', textAlign: 'center' }}>
                                        <div style={{ height: '0.75rem' }}></div>
                                        <div style={{ position: 'relative', width: '8rem', height: '8rem', overflow: 'hidden', borderRadius: '0.5rem', marginBottom: '0.5rem' }}>
                                            <img
                                                src={row.image_link && row.image_link !== 'No image found' ? row.image_link : 'https://i.imgur.com/iioqaDS_d.webp?maxwidth=760&fidelity=grand'}
                                                alt="Image description"
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop in case fallback image fails
                                                    e.target.src = 'https://i.imgur.com/iioqaDS_d.webp?maxwidth=760&fidelity=grand';
                                                }}
                                            />
                                        </div>
                                        <div style={{ height: '0.75rem' }}></div>
                                    </td>
                                </tr>

                                {isExpanded && (
                                    <React.Fragment>
                                        <tr>
                                            <td colSpan={2}>
                                                <div>
                                                    <span style={{ whiteSpace: "pre-line" }}>
                                                        <div style={{ height: '0.75rem' }}></div>
                                                        {row.current_summary.replace(/^-\. /gm, "\u2022 ").replace(/^- /gm, "\u2022 ").replace(/\*/g, '').replace(/^\s*$(?:\r\n?|\n)/gm, '')}
                                                    </span>
                                                </div>
                                                <div style={{ height: '0.75rem' }}></div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );

    return (
        <div>
            {isMobile ? renderMobileTableBeta() : renderDesktopTableBeta()}
        </div>
    );

}

export default DataTableCluster;